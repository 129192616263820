/* eslint-disable react/prop-types */
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import MainLogo from "../../assets/mainLogo.png";
import numberToWords from '../../Hooks/converter';


const OrderInvoice = ({ selectedOrder }) => {

    const getFormattedDate = () => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1; // January is 0!
        const year = today.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const styles = StyleSheet.create({
        page: {
            fontSize: 11,
            paddingTop: 20,
            paddingLeft: 40,
            paddingRight: 40,
            lineHeight: 1.5,
            flexDirection: 'column',
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: 20,
            position: 'relative',
        },
        leftSection: {
            flex: 1,
            alignItems: 'flex-start',
        },
        rightSection: {
            flex: 1,
            alignItems: 'flex-end',
        },
        logo: {
            width: 70,
        },
        section: {
            marginBottom: 10,
        },
        invoiceDetails: {
            fontSize: 10,
            marginTop: 5,
        },
        tableHeader: {
            flexDirection: 'row',
            backgroundColor: '#DEDEDE',
            borderColor: 'whitesmoke',
            borderBottomWidth: 1,
            padding: 5,
        },
        tableRow: {
            flexDirection: 'row',
            borderColor: 'whitesmoke',
            borderBottomWidth: 1,
            padding: 5,
        },
        tableCell: {
            flex: 1,
            textAlign: 'center',
        },
        totalSection: {
            marginTop: 20,
            fontSize: 12,
            textAlign: 'right',
        },
        amountInWords: {
            marginTop: 10,
            fontSize: 10,
        },
        sealAndSignature: {
            marginTop: 30,
            flexDirection: 'column',
            alignItems: 'flex-end',
            position: 'absolute',
            bottom: 20,
            right: 40,
        },
        signature: {
            textAlign: 'center',
            fontSize: 10,
            marginTop: 5,
        },
        seal: {
            width: 70,
            marginBottom: 5,
        },
    });

    const InvoiceHeader = () => (
        <View style={styles.header}>
            <View style={styles.leftSection}>
                <Text style={styles.invoiceDetails}>Invoice To</Text>
                <Text>{selectedOrder?.customer?.name}</Text>
                <Text>{selectedOrder?.customer?.address?.Location}</Text>
                <Text>{selectedOrder?.customer?.phone}</Text>
                <Text style={styles.invoiceDetails}>Invoice From</Text>
                <Text>Kansa Pital</Text>
                <Text>Sarenga, Bankura, West Bengal 722150</Text>
                <Text>GSTIN: 19HTDPM2142E1ZS</Text>
                <Text>Order ID: {selectedOrder?.order_id || selectedOrder?._id}</Text>
                <Text>Date: {getFormattedDate()}</Text>
            </View>
            <View style={styles.rightSection}>
                <Image style={styles.logo} src={MainLogo} />
            </View>
        </View>
    );

    const InvoiceTable = () => (
        <View>
            <View style={styles.tableHeader}>
                <Text style={styles.tableCell}>Sn</Text>
                <Text style={styles.tableCell}>Item Name</Text>
                <Text style={styles.tableCell}>Size</Text>
                <Text style={styles.tableCell}>Quantity</Text>
                <Text style={styles.tableCell}>Price</Text>
                <Text style={styles.tableCell}>With Tax</Text>
            </View>
            {selectedOrder?.items?.map((item, index) => (
                <View key={item._id} style={styles.tableRow}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                    <Text style={styles.tableCell}>{item.name}</Text>
                    <Text style={styles.tableCell}>{item.size}</Text>
                    <Text style={styles.tableCell}>{item.quantity}</Text>
                    <Text style={styles.tableCell}>{item.price}</Text>
                    <Text style={styles.tableCell}>{(item.price * 1.12).toFixed(2)} &nbsp; ({item.productTax}%)</Text>
                </View>
            ))}
        </View>
    );

    const InvoiceTotal = () => {
        const total = selectedOrder?.totalAmount;
        const roundedTotal = Math.round(total);
        // const difference = roundedTotal - total;

        return (
            <View style={styles.totalSection}>
                {/* <Text>Rounding Adjustment: {difference.toFixed(2)}</Text> */}
                <Text>Total Amount: {roundedTotal.toFixed(2)} (After Round Adjustment)</Text>
                <Text style={styles.amountInWords}>
                    Total Amount in Words: {numberToWords(roundedTotal)}
                </Text>
            </View>
        );
    };

    const SealAndSignature = () => (
        <View style={styles.sealAndSignature}>
            <Image style={styles.seal} src={MainLogo} />
            <Text style={styles.signature}>Authorized Seller Signature</Text>
        </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceHeader />
                <InvoiceTable />
                <InvoiceTotal />
                <SealAndSignature />
            </Page>
        </Document>
    );
};

export default OrderInvoice;