/* eslint-disable no-unused-vars */
import React from "react";
import SlidingImg from "../../Components/Home/SlidingImage";
import CategoryProdact from "../../Components/Product/CategoryProduct/CategoryProduct";
import AboutUs from "../../Components/AboutUs/AboutUs";

const Home = () => {
  return (
    <div className="w-full min-h-screen">
      {/* Full-width container for the slider */}
      <div className="my-0 w-full h-screen">
        <SlidingImg />
      </div>

      <div className="my-5">
        <h1 className="text-4xl text-center font-semibold my-5">Category Product</h1>
        <CategoryProdact />
      </div>

      <div className="my-20">
        {/* Add About us for the home page */}
      </div>
    </div>
  );
};

export default Home;

