// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAvgjELyY8d2BAcF-LKkGRIgcKw7mKYBLM",
  authDomain: "kasapital-2024.firebaseapp.com",
  projectId: "kasapital-2024",
  storageBucket: "kasapital-2024.appspot.com", // Corrected storage bucket
  messagingSenderId: "151873347361",
  appId: "1:151873347361:web:ce1dbd5e44f755a830e860",
};

// Initialize Firebase
let app;
try {
  app = initializeApp(firebaseConfig);
  console.log("Firebase initialized successfully");
} catch (error) {
  console.error("Firebase initialization error", error);
}
export default app;
