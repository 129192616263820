/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { saveLocalData } from "../../Shared/LocalStorage";
import useAuth from "../../../Hooks/UseAuth";
import { FaStar } from "react-icons/fa6";
import { Live_URL } from "../../../config";

const CategoryAllProductcard = ({ data }) => {
  const { pathname } = useLocation();
  const [price, setPice] = useState();
  const [activeSize, setActiveSize] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { image, title, rating, _id, priceBySize } = data;
  const { maulLoading, setMaulLoading } = useAuth();
  const [review, setReview] = useState(0);

  useEffect(() => {
    fetch(`${Live_URL}/api/comment/${_id}`)
      .then((res) => res.json())
      .then((data) => {
        setReview(data.length)
      });
  }, [_id]);





  useEffect(() => {
    const data = priceBySize[0]
    const keys = Object.keys(data);
    const keyName = keys[0];
    const values = Object.values(data);
    const valuesName = values[0];
    setActiveSize(keyName)
    setPice(valuesName)
  }, [priceBySize])




  const priceNumber = +price;

  const calculateTotal = (priceNumber, quantityOfNumber, gstRate) => {
    const t = quantityOfNumber * priceNumber;
    return t + (t * gstRate) / 100;
  };

  const totalAmount = calculateTotal(priceNumber, 1, +data?.productTax || 18);
  console.log(totalAmount, data?.productTax, price);

  // make a local stooge data post;
  const firstImage = data.links[0];

  const addToCurd = (postData) => {
    const { _id, name, links } = postData;
    const productSize = activeSize;
    const localPostdata = {
      _id,
      name,
      image: firstImage.url,
      originalPrice: price,
      price: totalAmount,
      quantity: 1,
      size: activeSize,
      customizeTexts: '',
      customizeImage: '',
      discount: 0,
      AmazonLink: data?.AmazonLink,
      productTax: data?.productTax || 0,
    };
    saveLocalData(localPostdata, _id, productSize);
    setMaulLoading(!maulLoading);
  };

  return (
    <div className="bg-cardBg pb-3 mx-auto shadow-md rounded-lg w-80">
      <Link to={`/productDetails/${_id}`}>
        <div className="slide-container rounded-lg">
          <img
            src={data?.links[0]?.url}
            alt=""
            className="h-72 xl:h-[270px] md:h-[260px] lg:h-[280px] rounded-lg object-cover"
          />
        </div>
        <h1 className="text-2xl font-bold   text-mainColor">{data?.name}</h1>
      </Link>
      <div className="p-2">
        <div className="">
          <h1 className="font-bold text-xl text-black">
            ₹<span>{price}</span>
          </h1>
          <h1 className="font-bold text-black">
            <div>
              <h1 className="font-semibold text-2xl text-black flex items-center ">
                {" "}
                <span>
                  <FaStar size="15" className="text-yellow-500" />
                </span>
                <span>
                  <FaStar size="15" className="text-yellow-500" />
                </span>
                <span>
                  <FaStar size="15" className="text-yellow-500" />
                </span>
                <span>
                  <FaStar size="15" className="text-yellow-500" />
                </span>
                <span>
                  <FaStar size="15" className="text-yellow-500" />
                </span>
                <span className="text-sm font-normal">({review})</span>

              </h1>
            </div>
          </h1>
        </div>
        <div className="flex justify-center mt-3">
          {" "}
          {/* Added flex container */}
          <button
            onClick={() => addToCurd(data)}
            className="bg-yellow-500 hover:bg-yellow-600 px-2 py-1 rounded-md text-black"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryAllProductcard;
