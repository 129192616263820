export default function numberToWords(amount) {
    const ones = [
        "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
    ];
    const tens = [
        "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
    ];
    const teens = [
        "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];

    if (amount === 0) return "Zero";

    let words = "";

    if (amount >= 1000) {
        words += ones[Math.floor(amount / 1000)] + " Thousand ";
        amount %= 1000;
    }

    if (amount >= 100) {
        words += ones[Math.floor(amount / 100)] + " Hundred ";
        amount %= 100;
    }

    if (amount >= 20) {
        words += tens[Math.floor(amount / 10)] + " ";
        amount %= 10;
    }

    if (amount >= 11 && amount <= 19) {
        words += teens[amount - 11] + " ";
        amount = 0;
    }

    if (amount > 0 && amount < 10) {
        words += ones[amount] + " ";
    }

    return words.trim();
}