/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Live_URL } from '../../config';

const SlidingImg = () => {
  const [slider, setSlider] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetch(`${Live_URL}/api/slider`)
      .then((res) => res.json())
      .then((data) => {
        setSlider(data);
      })
      .catch(() => {
        setLoad(!load);
      });
  }, [load]);

  return (
    <div
      className="slide-container w-full h-screen z-0"
      style={{
        position: 'relative',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      {slider.length > 0 && (
        <Fade
          duration={Infinity}
          transitionDuration={3000}
          autoplay={true} // Optional, default: false
          infinite
          indicators={true}
          arrows={false}
        >
          {slider.map((slideImage, index) => (
            <div
              key={index}
              className="w-full h-screen"
              style={{
                backgroundImage: `url(${slideImage?.image?.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div
                className="text-center text-white h-full w-full flex flex-col items-center justify-center"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.4)', // Optional overlay for better readability
                }}
              >
                <h1 className="text-4xl sm:text-6xl font-bold font-serif">
                  {'Welcome to Kansapital'}
                </h1>
                <p className="mt-4 text-lg sm:text-xl">
                  {/* {slideImage?.description || 'Default description text.'} */}
                </p>
              </div>
            </div>
          ))}
        </Fade>
      )}
    </div>
  );
};

export default SlidingImg;
