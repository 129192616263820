/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { FaIndianRupeeSign, FaRupeeSign, FaStar } from "react-icons/fa6";
import { Link, useLoaderData, useLocation } from "react-router-dom";
import { saveLocalData } from "../../Shared/LocalStorage";
import useAuth from "../../../Hooks/UseAuth";
import { uploadCloudinary } from "../../../utils/imageUpload";
import RelatedProducts from "../RelatedProducts/RelatedProducts";
import Comment from "./Comment";
import Review from "./Review";
import Modal from "../../PmentPage/Modal";
import { Live_URL } from "../../../config";

const ProductDetls = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const {
    name,
    price: productPrice,
    description,
    priceBySize,
    couponCode: codes,
    links,
    _id,
    category,
    productTax
  } = useLoaderData();

  const [washinData, setWashinData] = useState([])

  const firstImage = links[0];
  const [activeSize, setActiveSize] = useState("");
  const [discount, setDiscount] = useState(0);
  const { setMaulLoading, maulLoading, user } = useAuth();
  const [quantityOfNumber, setQuantityOfNumber] = useState(1);
  const [customizeImage, setCustomizeImage] = useState(null);
  const [customizeTexts, setCustomizeTexts] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [review, setReview] = useState(0);

  const [ProductImg, setProductImg] = useState(firstImage.url);




  // this is a product size base price
  const [price, setPice] = useState();

  useEffect(() => {
    setProductImg(firstImage.url);
  }, [firstImage.url]);



  useEffect(() => {
    const data = priceBySize[0]
    const keys = Object.keys(data);
    const keyName = keys[0];
    const values = Object.values(data);
    const valuesName = values[0];
    setActiveSize(keyName)
    setPice(valuesName)
  }, [priceBySize])


  // CouponCode
  const CouponCode = (e) => {
    const code = e.target.value;
    const coupon = codes;
    const lastTwoDigits = coupon.slice(-2);
    if (code === coupon) {
      setDiscount(parseInt(lastTwoDigits));
    }
  };

  // counter
  const CountPlus = () => {
    setQuantityOfNumber(quantityOfNumber + 1);
  };
  const CountManas = () => {
    if (quantityOfNumber > 1) {
      setQuantityOfNumber(quantityOfNumber - 1);
    }
  };

  const calculateTotal = (price, quantityOfNumber, gstRate, discount) => {
    const t = quantityOfNumber * price;
    const p = t + (t * gstRate) / 100;
    if (discount > 0) {
      const l = p - (p * discount) / 100;
      return l
    } else {
      return p;
    }
  };
  const totalA = calculateTotal(price, quantityOfNumber, +productTax ?? 18, discount);
  const totalAmount = Math.ceil(totalA);

  // customize section
  const handleChanges = async (e) => {
    try {
      const files = Array.from(e.target.files);
      for (let i = 0; i < files.length; i++) {
        const data = await uploadCloudinary(files[i]);
        setCustomizeImage(data.url);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const customizeText = (e) => {
    setCustomizeTexts(e.target.value);
  };
  const localPostdata = {
    _id,
    name,
    image: firstImage?.url,
    originalPrice: price,
    price: totalAmount,
    quantity: quantityOfNumber,
    size: activeSize,
    customizeTexts,
    customizeImage,
    discount: discount,
  };
  // make a local stooge data post;
  const addToCurd = (_id, name) => {
    saveLocalData(localPostdata, _id, activeSize);
    setMaulLoading(!maulLoading);
  };



  useEffect(() => {
    fetch(`${Live_URL}/api/washingKit`).then(res => res.json()).then(data => {
      setWashinData(data)
    })
  }, [])


  const wsk = washinData[0]


  const WashingData = {
    _id: wsk?._id,
    name: wsk?.name,
    image: wsk?.image?.url,
    originalPrice: wsk?.price,
    price: wsk?.price,
    quantity: 1,
    size: wsk?.size,
    customizeTexts: '',
    customizeImage: null,
    discount: 0,
  };

  const addWashing = () => {
    const as = 1
    const _id = wsk?._id
    if (washinData) {
      saveLocalData(WashingData, _id, as);
      setMaulLoading(!maulLoading);
    }
  }


  return (
    <div>
      <div className=" my-5 mx-4 grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-6  grid-cols-1">
        <div>
          <img src={ProductImg} alt="" className="w-full xl:h-96" />
          {/* */}
          <div className="">
            {links?.map((image, index) => (
              <>
                <div
                  className="avatar mx-3 my-2 border-2 border-black rounded"
                  onClick={() => {
                    setProductImg(image.url);
                  }}
                  key={index + 1}
                >
                  <div className="w-20 rounded">
                    <img src={image.url} />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div>
          <div className=" text-black">
            <h1 className="font-semibold  text-2xl text-black">{name}</h1>
            <div className="flex justify-between">
              <h1 className="font-semibold text-2xl text-black flex items-center gap-1">
                {" "}
                <span>
                  <FaIndianRupeeSign size="15" />
                </span>
                {totalAmount}
              </h1>
              <div>
                <h1 className="font-semibold text-2xl text-black flex items-center ml-4">
                  {" "}
                  <span>
                    <FaStar size="15" className="text-yellow-500" />
                  </span>
                  <span>
                    <FaStar size="15" className="text-yellow-500" />
                  </span>
                  <span>
                    <FaStar size="15" className="text-yellow-500" />
                  </span>
                  <span>
                    <FaStar size="15" className="text-yellow-500" />
                  </span>
                  <span>
                    <FaStar size="15" className="text-yellow-500" />
                  </span>
                  <span className="text-sm font-normal">({review})</span>

                </h1>
              </div>

            </div>
          </div>
          {/*  */}

          <div>
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-3 my-2">
              <div>
                <h1 className="text-2xl font-semibold text-black">Size :</h1>


                {priceBySize && (
                  <div className="flex">
                    {priceBySize?.map((item) => {
                      const key = Object.keys(item)[0];
                      const value = item[key];
                      return (
                        <button
                          key={key}
                          onClick={() => {
                            setActiveSize(key),
                              setPice(+value)
                          }}
                          className={`mx-2 px-3 py-1 rounded-m ${activeSize === key ? 'bg-blue-700 text-white' : 'bg-slate-100 border text-black'
                            }`}
                        >
                          {key}
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>

              <div>
                <h1 className="text-xl font-semibold text-black">Quantity :</h1>
                <div className="border-2 flex justify-between p-2 rounded-md ">
                  <button
                    onClick={CountManas}
                    className="ml-5 bg-yellow-500 hover:bg-yellow-600 px-6 text-black rounded-md py-1"
                  >
                    -
                  </button>
                  <h4 className="text-black">{quantityOfNumber}</h4>
                  <button
                    onClick={CountPlus}
                    className="mr-5 bg-yellow-500 hover:bg-yellow-600 px-6 text-black rounded-md py-1"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            {/*Customize your product  */}
            <div className="my-5">
              <div></div>
              <h1 className="text-black font-bold">Customize your product :</h1>

              <div>
                <label
                  htmlFor="message"
                  className="block text-black mb-1 ml-1  "
                >
                  Add Title
                </label>
                <input
                  onChange={customizeText}
                  type="text"
                  placeholder=" Add Title"
                  className="w-full px-3 py-2 border-2 border-black rounded-md bg-gray-100 "
                />
              </div>

              <div>
                <div>
                  <label
                    htmlFor="message"
                    className="block text-black mb-1 ml-1"
                  >
                    Add Logo
                  </label>
                  <input
                    type="file"
                    onChange={handleChanges}
                    placeholder="Add Logo"
                    className=" w-full border-2 border-black"
                  />
                </div>
              </div>
            </div>

            {/*Coupon code  */}
            <div className="my-5">
              <h1 className="text-black font-bold">Coupon code :</h1>

              <input
                onChange={CouponCode}
                type="text"
                placeholder=" Add Coupon code"
                className="w-full px-3 py-2 border-2 border-black rounded-md bg-gray-100 "
              />
            </div>

            {/* add to card buy now */}
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-3 my-2">
              <button
                onClick={() => addToCurd(_id, name)}
                className="bg-yellow-300 hover:bg-yellow-400 px-2 w-full  py-3 rounded-md text-black"
              >
                Add to Cart
              </button>

              {user?.uid ? <button className="bg-yellow-400 hover:bg-buttonHoverColor  px-4 py-2 text-black rounded-lg" onClick={() => document.getElementById('my_modal_5').showModal()}>BUY IT NOW </button> : <Link to='/login' className="bg-yellow-400 hover:bg-buttonHoverColor  px-4 py-2 text-black rounded-lg" onClick={() => {
                document.getElementById('my_modal_5').showModal()

              }
              }>             Buy Now
              </Link>}

            </div>

            <Modal totalPrice={totalAmount} products={[localPostdata]} />




            {/* descriptions */}


            {/*bottom section  */}

            <h1 className="text-black my-5">
              *Tax included. Shipping calculated at checkout.
            </h1>

            <div>
              <button onClick={addWashing} className="bg-slate-400 px-8 hover:bg-gray-300 py-2 rounded-xl text-black">
                Add Washing kit{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h1 className="mt-1 text-slate-700">{description}</h1>
        </div>
      </div>
      <div className="my-5">
        <h1 className="text-2xl font-bold my-4 text-black">Customer reviews</h1>
        <div>
          <Comment id={_id} dataUpdated={dataUpdated} setReview={setReview} />
        </div>

        {user && <div>
          <Review id={_id} setDataUpdated={setDataUpdated} />
        </div>}
      </div>
      {/* RelatedProducts */}
      {/* RelatedProducts */}
      <RelatedProducts category={category} />

    </div>
  );
};

export default ProductDetls;
