import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {React} from 'react-caches';
// import { uploadCloudinary } from "../../utils/imageUpload";

import toast, { Toaster } from "react-hot-toast";
import useAuth from "../../Hooks/UseAuth";
// import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import { Live_URL } from "../../config";

const CreateAccount = () => {
  // const [imagePreview, setImagePreview] = useState(null);
  // const [file, setFile] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [isEmail, setIsEmail] = useState("'afd@gmail.com'");
  const [haveUser, setHaveUser] = useState(null);

  const navigate = useNavigate();
  const { user, createUser, updateNameAndPhoto, logOut, sendEmailVerificationLink } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();



  // const handleFileChange = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setFile(file);
  //     setImagePreview(URL.createObjectURL(file));
  //   }
  // };

  const onSubmit = async (dataForm) => {
    const email = dataForm?.email;
    setIsEmail(email);
    const password = dataForm?.password;
    const name = React.Random.Word(15)
    const phonenumber = dataForm?.mobileNumber;
    let photo;
    try {
      if (haveUser?.email !== email || !haveUser || haveUser == null) {
        setCreateLoading(true);
        // if (file !== null) {
        //   photo = await uploadCloudinary(file);
        // }

        createUser(email, password)
          .then((result) => {
            const user = result?.user;
            
            sendEmailVerificationLink(user).then(() => {
              toast.success("Check your email for verification.");
            })
            const data = {
              profileName: name,
              profileImage: photo ? photo : undefined,
              email,
              phonenumber,
              userUid: user.uid,
              password: password
            };

            updateNameAndPhoto(result.user, name, photo?.url)
              .then(async () => {
                const res = await axios.post(`${Live_URL}/api/user`, data)
                // localStorage.setItem('token', res?.data?.token); // need for JWT authentication
                if (res.status === 200) {
                  setCreateLoading(false);
                  // toast.success("success sign up !!");
                  // navigate(from, { replace: true });
                  logOut();
                  navigate('/check-email', { replace: true });
                  reset()
                }
              })
              .catch(err => {
                setCreateLoading(false);
                toast.error("Failed: ", err.message);
              })
          })
          .catch((err) => {
            setCreateLoading(false);
            toast.error("Failed: ", err.message);
          });
      } else {
        setCreateLoading(false);
        toast.error("Already use this email!");
      }
    } catch (error) {
      toast.error("Faild: ", error);
    }
  };

  useEffect(() => {
    async function loadUser() {
      const res = await axios.get(`${Live_URL}/api/user/${isEmail}`)
      setHaveUser(res.data)
    }
    loadUser()
  }, [isEmail])

  if (!user) {
    return (
      <div>
        <Toaster />
        <div className="flex flex-col max-w-md p-4 rounded-md sm:p-10 bg-cardBg mx-auto my-5 text-gray-800">
          <div className="mb-6 text-center">
            <h1 className="text-4xl font-bold">Sign Up</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-12">
            <div className="space-y-6">
             {/* <div>
                <label htmlFor="text" className=" mb-2 text-sm">
                  User Name
                </label>
                <input
                  type="text"
                  {...register("userName", { required: true })}
                  placeholder="type your user name"
                  className="w-full px-3 py-2 border rounded-md bg-gray-100  "
                />
              </div>
              <div>
                <label htmlFor="message" className="block mb-1 ml-1">
                  Update Profile
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  placeholder="Update profile"
                  className=" w-full border-2 border-black"
                  accept=".jpg, .png, .jpeg"
                />
                <div className="avatar gap-2 mt-2">
                  {imagePreview && <div className="w-14 rounded">
                    <img src={imagePreview} alt={`file preview`} />
                    <span className="bottom-1 cursor-pointer absolute text-2xl text-red-600" onClick={() => setImagePreview(null)}><MdDeleteForever /></span>
                  </div>}

                </div>
              </div> */}
              <div>
                <label htmlFor="number" className=" mb-2 text-sm">
                  Mobile Number
                </label>
                <input
                  type="number"
                  {...register("mobileNumber", { required: true })}
                  placeholder="type your user name"
                  className="w-full px-3 py-2 border rounded-md bg-gray-100  "
                />
              </div>
              <div>
                <label htmlFor="email" className=" mb-2 text-sm">
                  Email address
                </label>
                <input
                  type="email"
                  {...register("email", { required: true })}
                  placeholder="leroy@jenkins.com"
                  className="w-full px-3 py-2 border rounded-md bg-gray-100  "
                />
              </div>
              <div>
                <div className="flex justify-between mb-2">
                  <label htmlFor="password" className="text-sm">
                    Password
                  </label>
                </div>
                <input
                  type="password"
                  {...register("password", { required: true })}
                  placeholder="*****"
                  className="w-full px-3 py-2 border rounded-md  bg-gray-50 text-gray-800"
                />
              </div>
            </div>

            <button type="submit" disabled={createLoading && true} className={`w-full px-8 py-3 font-semibold cursor-pointer rounded-md bg-yellow-500 hover:bg-yellow-600  text-black`}>
              {createLoading ? "Creating..." : "Create Account"}

            </button>
          </form>
          <p className="px-6 mt-4 text-sm text-center text-gray-600">
            I have an account !
            < Link
              to='/login'
              className="hover:underline cursor-pointer text-violet-600"
            >
              Login
            </Link>
            .
          </p>
        </div>
      </div>
    );
  } else return navigate('/', { replace: true });
};

export default CreateAccount;
